import React from 'react'

function About () {
  return (
    <div className='card' id='about-milagro' >
      <h2>
        What is Milagro?
      </h2>
      <p>
        How to get?
      </p>
      <p>
        To have access to the software, first you have to reach our developer: 
        </p>
      <p>
        Email: contact@milagro.cc
      </p>
      <p>
        Phone no: +551130814328
      </p>
      <br/>
      <p>
        Once this step is done, the user will receive an email with the ACTIVATION CODE.
      </p>   
    </div>
  )
}

export default About
