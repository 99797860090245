import React from 'react'

function Introduction () {
    return (
    <div className='card' style={{marginTop: '100px'}}>
      <h2>
          Introduction
      </h2>
      <div className='description'>
        <p>
        In this brief material, we will make it clear to the user how to make the most of their experience with Milagro.
        </p>
        <p>
        Our software counts on outstanding features that provide speed and quality when producing your media.
        </p>
        <p>
        Above everything, we hope it will contribute to development of both people and company. That’s why we invite everybody 
        to experience it thoroughly, by following us on our social media:
        </p>
        <br></br>
        <p>
        <a href="https://www.facebook.com/milagroapp/" rel="noopener noreferrer" target="_blank">
            Facebook
        </a>
        </p>
        <p>
        <a href="https://www.linkedin.com/company/milagroapp/" rel="noopener noreferrer" target="_blank">
            Youtube
        </a>
        </p>
        <p>
        <a href="https://www.linkedin.com/company/milagro/about/" rel="noopener noreferrer" target="_blank">
            Linkedin
        </a>
        </p>
      </div>
    </div>
    )
}

export default Introduction
