import React from 'react'

function Element () {
  return (
    <div className='card' style={{marginTop: '100px'}}> 
      <h2>
        Element
      </h2>
      <p>
        In this tab, each element is treated independently. It makes possible to move the object, so it is here we make the
        animation.
      </p>
      <p>The top of the tab is where we have the preview and the fixed parameters.</p>
      <br></br>
      <p>When an element comes from PSD, the parameters for top, left, width and height of this file are incorporated in
        Milagro. Because of that, the art made previously won’t suffer any changes during the importing of assets.</p>
      <h3 id='element-preview'>
        Preview
      </h3>
      <p>On preview, it is possible to see the selected layer along with its positioning and key dimensions shown.</p>
      <p>Most of the times, this parameters are not altered during the animation, because it is made in a different panel.</p>
      <h3 id='element-category'>
        Category
      </h3>
      <p>It shows whether the layer already belongs to any animation category. We will explore this feature later.</p>
      <h3 id='element-anchor-point'>
        Anchor-point
      </h3>
      <p>It is possible to change the anchor-point of the element on this tab, but it is not an animated parameter.</p>
    </div>
  )
}

export default Element
