import React from 'react'
import BibliotecaImg from '../assets/biblioteca.png'

function Animation () {
  return (
    <div className='card' style={{marginTop: '100px'}}>      
      <h2>Animation</h2>

      <p>Inside the element tab there is the animation sector. It is where all the file animation is made. The window behaves
        differently for each selected area of the timeline.</p>
      <br></br>
      <p>When you select a preset, the tab will show preset actions.</p>
      <p>
        When you select a keyframe, it will show keyframe options.
      </p>
      <h3 id='animation-keyframe'>Keyframe</h3>
      <p>All animations in Milagro are made via keyframes.</p>
      <p>When there is no keyframe in a layer, the object won’t appear, for that is where the parameters are located.</p>
      <p>
        Keyframes are the frames in which the user configures the positions and parameters of the elements.
        Each keyframe has its own parameters.
      </p>
      <br></br>
      <p>Animation happens in the interval between two keyframes with different parameters.</p>
      <p>Creating them is as simple as a double click on the timeline.</p>
      <br></br>
      <p>
        Selecting a keyframe is done by clicking and it is represented by the color yellow.
        Once selected, a parameter box becomes visible and it makes it possible to alter the positions of the object in
        that keyframe.
      </p>
      <h3 id='animation-keyframe-parameters'>Keyframe parameters</h3>
      <p>The main parameters for animations are translate, skew, rotate and scale.</p>
      <p>In Keyframe selection, the user sees the animation screen with these parameters.</p>
      <li>
        Translate: moves the position of X and Y on the stage.

          OBS: We decided not to use Translatre Z at the moment, due to compatibility issues with some browsers.
          Animations that require so may be done with Scale.
        
      </li>
      <li>
        Skew: distorts image.
      </li>
      <li>
        Rotate: allows to rotate the object on 3 different axes.
      </li>
      <li>
        Axis Z: Traditional rotation axis.
      </li>
      <li>
        Axes X and Y: Both of them are 3D rotation, used for “door-like” movements.
      </li>
      <li>        
          Scale: used in percentage. 100% represents the object’s original size.
          It is possible to use Scale with separated parameters by deactivating “lock”.        
      </li>
      <li>
        Opacity: Alpha. This parameter goes from 0% to 100%, 0% being invisible.
      </li>
      <h3 id='animation-add-property'>Add Property</h3>
      <p>It is possible to add new parameters of animation by clicking on “add property”.</p>
      <h3 id='animation-ease'>Ease</h3>
      <p>Ease is always applied on the first keyframe, in order to define which ease will be applied from the selected
        keyframe to the other.</p>
      <p>To change it, it is necessary to click on the keyframe and select the ease.</p>
      <p>There are some predetermined ease options. By default, Milagro uses ease-in-out.</p>
      <br></br>
      <p>Na opção Custom, pode-se definir as curvas do ease. Após a edição da curva, é necessário clicar em "apply".</p>
      <p>The option Custom allows the user to define the ease curves. After editing the curve, it is necessary to click on
        “apply”.</p>
      <h3 id='animation-time'>Animation time</h3>
      <p>
        The interval between keyframes represents the animation time. To increase it, the user just have to increase the gap
        between the keyframes.
      </p>
      <h3 id='animation-presets'>Presets</h3>
      <p>Presets are pre set animations that can be applied to a layer or object category.</p>
      <p>Presets are also keyframe animations. However, it is not possible to edit their length.</p>
      <br></br>
      <p>To edit them, just right click them and select the option “convert to keyframes”.</p>
      <p>There are two types of presets:</p>
      <li>
        IN: Opening animations
      </li>
      <li>
        OUT: closing animations
      </li>
      <br></br>
      <p>Studio has got a preset library created by motion designers. The presets are separated by type of movement.</p>
      <img src={BibliotecaImg} alt='biblioteca' />
      <h3 id='animation-applying-preset'>Applying a preset</h3>
      <p>To apply a preset to a layer, click on an empty space and the button “add preset” will show up.</p>
      <h3 id='animation-altering-preset'>Altering a preset / Converting to keyframe</h3>
      <p>Click on the preset and the following options will show: change preset > convert to keyframes.</p>
      <h3 id='animation-deleting-preset'>Deleting um preset</h3>
      <p>Click on a preset and push the “delete” button on the keyboard.</p>
      <p>OBS: All animations are created to work on all browsers, even on IE.</p>
      <h3 id='animation-selection-keyframes'>Selection of keyframes and presets</h3>
      <p>The selection of objects is represented by the color yellow, and it works via clicking.</p>
      <p>The user may add keyframes, intervals and presets, and they may be single or multiple ones.</p>
      <br></br>
      <p>
        To select more than one object, keep the key “command” pushed and click on the new objects.
        Just click the layer name once to have all its elements selected.
      </p>
      <h3 id='animation-selection-box'>Selection Box</h3>
      <p>
        It is possible to create a selection box and add multiple keyframes at the same time,
        which greatly speeds up the time adjustment process.
      </p>
      <p>Doing so is as simples as a click and drag over the timeline. A yellow box will pop up.</p>
      <h3 id='animation-copy-paste-keyframe'>Copy and paste - keyframes and/or presets</h3>
      <p>
        It is possible to copy and paste an animation interval - whether it is a keyframe or a preset -
        right to another layer just by using "command + C" and "command + V". It works both on the same
        piece and on different pieces.
      </p>
    </div>
  )
}

export default Animation
