import styled from 'styled-components'

export const Container = styled.div `
  height: 460px;
  background-color: #242944;
  .d-none {
  display: none;
}

.subscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 155px;
}

.footer-followus {
  color: #ffffff;
  font-weight: bold;
  font-size: 28px;
}

.subscribe-container input {
  height: 52px;
  width: 417px;
  border-radius: 15px;
  margin: 0 30px;
  outline-color: #ddd;
  padding-left: 20px;
  font-size: 18px;
  outline: 0;

}

.email-footer-sended {
  height: 52px;
  width: 417px;
  border-radius: 15px;
  margin: 0 30px;
  background-color: #fff;
  padding-left: 20px;
  font-size: 18px;
  color: #ddd;
  text-align: center;
  line-height: 52px;

}

.subscribe-container input:focus {
  border: 5px solid #ddd;
  padding-left: 15px;

}

.subscribe-container input::placeholder {
  color: #ddd;
  font-size: 18px;
  padding-left: 34px;
}

.cta-subscribe {
  width: 200px;
  height: 60px;
  background-color: #242944;
  border-radius: 15%/50%;
  border: 2px solid #f34b5e;
  margin-right: 10px;
  color: #f34b5e;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline-color: #ddd;
  position: relative;
  transition: 0.3s;
  outline: 0;
}

.cta-subscribe img:first-child {
  transition: 0.3s;
  margin-left: 10px;
}

.cta-subscribe img:last-child {
  transition: 0.3s;
  right: 40px;
  position: absolute;
  opacity: 0;

}

.cta-subscribe:hover {
  background-color: #f34b5e;
  color: #ffffff;
}

.cta-subscribe:hover img:first-child {
  opacity: 0;

}

.cta-subscribe:hover img:last-child {
  opacity: 1;

}

.list-container {
  display: flex;
  align-items: top;
  justify-content: center;
  background-color: #242944;

}

.sections-list {
  position: relative;
  display: flex;
  width: 1232px;
  padding: 30px 0;
  border-top: 1px solid #464c6b;
  border-bottom: 1px solid #464c6b;
}

.followus-container {
  border-top: 1px solid #464c6b;
  width: 1232px;
  margin: 0 auto;
}

.sections-list p {
  margin: 30px 0px;
  font-weight: normal;
  color: #7a80a5;
  font-size: 16px;
  line-height: 22px;
  max-width: 670px;
  text-align: left;
}

.section-a>p>span {
  font-size: 20px;
  font-weight: bold;
}

.section-a {
  position: relative;
  width: 100%;
}

.section-a img {
  display: block;
  text-align: left;
}


.social-medias {
  right: 10%;
  width: 145px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 1%;
}

.startup-selo {
  position: absolute;
  right: 2%;
  bottom: 5%;
}


.section-b {
  position: relative;
  width: 210px;
}

.section-c {
  position: relative;
  width: 250px;
}

.section-d {
  position: relative;
  width: 130px;
}


.elements-ul li:first-child {
  color: #ffffff;
  font-weight: bold;
}

.elements-ul {
  margin-top: 6px;
  ;
  list-style: none;
  text-align: left;
  color: #7a80a5;
  font-size: 16px;
  font-weight: normal;
  line-height: 25px;
}

.text-copyright {
  display: block;
  font-weight: normal;
  background-color: #242944;
  color: #7a80a5;
  font-size: 13px;
  padding: 20px 0px;
  text-align: center;
}

.mobile-footer-division-a {
  display: flex;
}

@media (max-width: 1365px) {
  .footer-followus {
    font-size: 26px;
  }

  .sections-list,
  .followus-container {
    width: 90%;
  }

  .footer-followus {
    font-size: 24px;
  }

  .subscribe-container input,
  .email-footer-sended ,
  .email-footer-sended  {
    width: 297px;
  }

  .cta-subscribe {
    width: 180px;
    height: 50px;
  }

  .section-a {
    margin-left: 40PX;
    width: 100%;
  }

  .sections-list p {
    max-width: 500px;
  }

  .social-medias {
    right: 15%;
  }

  .section-b {
    width: 160px;
  }

  .section-c {
    width: 200px;
  }

  .startup-selo {
    position: absolute;
    right: 5%;
  }

  .cta-subscribe img:last-child {
    right: 29px;
  }
}


@media (max-width: 1023px) {

  .sections-list {
    width: 90%;
  }
  .cta-subscribe img:last-child {
    right: 128px;
  }

  .followus-container {
    width: 90%;

  }

  .subscribe-container {
    flex-direction: column;
    height: auto;
    padding: 30px 0;
  }

  .footer-followus {
    margin-bottom: 20px;
  }

  .subscribe-container input,
  .email-footer-sended  {
    width: 377px;
    height: 40px;
    margin-bottom: 20px;

  }

  .email-footer-sended  {
    line-height: 40px;
  }

  .cta-subscribe {
    width: 377px;
    height: 44px;
    border-radius: 15%/110%;
    margin: 0px 0px 30px 0px;
  }

  .subscribe-container input {
    text-align: center;
    padding: 0px;
  }

  .subscribe-container input::placeholder {
    text-align: center;
    padding: 0px;
  }

  .sections-list {
    flex-direction: column;

  }

  .social-medias {
    top: 0%;
    right: 0%;
  }

  .startup-selo {
    display: block;
    position: relative;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    right: 0px;
  }

  .section-a {
    width: 100%;
    margin: 0;
  }

  .section-a>* {
    position: relative;
    margin: 0 auto;
  }

  .section-a>p {
    text-align: center;
    margin: 30px auto;
    text-align: center;
  }

  .elements-ul {
    font-size: 17px;
    line-height: 26px;
  }

  .mobile-footer-division-a {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;

  }

  .mobile-footer-division-a>* {
    width: 220px;

  }

  .mobile-footer-division-a>*>* {
    text-align: center;

  }

  .section-d>ul {
    text-align: center;
  }

  .mobile-footer-division-b {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .mobile-footer-division-b>div {
    width: 220px;
    margin-right: 88px;

  }

  .mobile-footer-division-b>img {
    position: relative;
    right: 60px;
    margin-left: 44px;
  }

  .list-container {
    height: 442px;
  }

}

@media(max-width: 767px) {
  .list-container {
    height: 472px;
  }
}

@media (max-width: 479px) {

  .sections-list {
    height: 480px;

  }

  .cta-subscribe img:last-child {
    right: 108px;
  }

  .footer-followus {
    font-size: 21px;
  }

  .subscribe-container input,
  .cta-subscribe {
    border-radius: 10%/80%;
    width: 320px;
    font-size: 14px;
  }

  .email-footer-sended {
    width: 320px ;
  }

  .section-a>p {
    max-width: 340px;
    font-size: 15px;
    line-height: 19px;
  }

  .section-a>p>span {
    font-size: 15px;
  }

  .mobile-footer-division-a>* {
    width: 180px;
  }

  .mobile-footer-division-b>div {
    width: 180px;
    margin-right: 48px;
  }

  .list-container {
    height: 472px;
  }

}

`