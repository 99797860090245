import styled from 'styled-components'

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    background: #f0f5f9;
    max-width: 100%;
    height: 100%;
    overflow-y: scroll;    
    scroll-behavior: smooth;
 

    h2 {
      font-size: 45px;
      color: #f34b5e;
      font-family: 'roboto';
      font-weight: bold;
      margin-bottom: 30px;
      margin-top: 100px;
    }

    h3 {
      font-size: 24px;
      color: #242944;
      font-family: 'roboto';
      font-weight: bold;
      margin-bottom: 0px;
    }

    h4 {
      font-size: 20px;
      color: #f34b5e;
      font-family: 'roboto';
    }

    p, li {
      font-size: 18px;
      color: #242944;
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: 'roboto';
    }

    li {
      font-size: 18px;
      color: #242944;
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: 'roboto';
      margin-left: 40px;
    }

    .card {
      margin-left: auto;
      margin-right: auto;
      padding: 0px 20% 0px 20%;
      
    }

    .description {
      display: flex;
      flex-direction: column;

    }

    a {
      color: #242944;
      font-family: 'roboto';
    }

    img {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    @media (max-width: 767px) {
    overflow-y: none;

    .menu-wrapper {
      display: none
    }
    img {
      width: 100%;
    }

    .card {
      width: 80%;
      padding: 0;
     }
  }
 
`