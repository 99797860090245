import React from 'react'
import SignupImage from '../assets/SignUp.png'

function Signup () {
  return (
    <div className='card' >
    <h2>
      Sign Up
    </h2>
    <p>
      When you first access Milagro Studio, go to the SIGN UP screen and fill the required fields.
    </p>
    <img src={SignupImage} alt="signup"/>
  </div>
  )
}

export default Signup
