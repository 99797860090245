import React from 'react'
import PreviewGif from '../assets/borda.gif'

function Preview () {
  return (
    <div className='card' style={{marginTop: '100px'}}>    
    <h2>
      Preview
    </h2>
    <p>
      The preview is the stage your animation is on. That is where you can see how your animation looks like in 
      real time. You can also check the quality of the images being used.
    </p>
    <img src={PreviewGif} alt="stage"/>
    <p>
      There is a button on the top right corner which allows the user to see the objects off stage. 
      It makes it easier to see the objects that are out of the piece area. It is activated by default.
    </p>
  </div>
  )
}

export default Preview
