import React from 'react'
import ShortcutImg from '../assets/shortcut.jpg'

function CheckUpdates () {
  return (
    <div className='card' style={{marginTop: '100px'}}> 
      <h2>Check for Updates</h2>
      <p>Any time the software is initialized, it automatically checks for updates.</p>
      <p>For manual checking, access the menu bar to do so.</p>
      <p>Go to Milagro Studio > Check for updates.</p>
      <h3 id='checkupdates-templates'>
        Templates - Download
        </h3>
      <p>We have made some really helpful files files available for download. They may be used for animation study purposes.
        They also contain examples of names and structures.</p>
      <br></br>
      {/* <p><a>Link de Download do Template</a></p> */}
      <p>Content</p>
      <li>
        PSDs
      </li>
      <li>
        MLG dof a main piece
      </li>
      <li>
        Training PDF
      </li>
      <h3 id='checkupdates-shortcuts'>
        Shortcuts
      </h3>
      <img src={ShortcutImg} alt='shortcut'/>
    </div>
  )
}

export default CheckUpdates
