import React from 'react'

function FAQ () {
  return (
    <div className='card' style={{marginBottom: '100px'}}>
      <h2>FAQ</h2>
      <p>May I use the license in more than one machine?</p>
      <p>To transfer the license to a different machine, it is necessary to send an email to contact@milafro.cc and ask for a
        license reset.</p>
      <p>In case you have more than one license, we ask you to tell us the email/user which will have the access blocked.</p>
      <h3>What is a main piece?</h3>
      <p>In a campaign with many media formats, it is common to produce a single piece first, which will be approved by the
        client. This piece will work as a guideline to the rest of the campaign, and it is normally called main piece.</p>
      <h3>Which type of closing should I use?</h3>
      <p>Check the media plan to find out the required closing for the banner.</p>
      <h3>How do I change the type of closing?</h3>
      <p>Go to File > Edit Project.</p>
      <h3>I got an error message, but I am not quite sure what is wrong? What should I do?</h3>
      <p>Sometime these problems occur due to a system error. Cleaning the register will solve this issue.</p>
      <p>Go to Milagro Studio > Clear database.</p>
      <h3>How do I schedule a training course?</h3>
      <p>Talk to us, we are here to help.</p>
      <p>Email: contact@milagro.cc</p>
      <p>Tel: +551130814328</p>
    </div>
  )
}

export default FAQ
