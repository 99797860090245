import React, { useState }  from 'react'
import SearchIcon from '../../assets/search_icon.png'
import Introduction from '../../pages/introduction'
import About from '../../pages/about'
import Signup from '../../pages/signup'
import Signin from '../../pages/signin'
import Home from '../../pages/home'
import Stage from '../../pages/stage'
import Preview from '../../pages/preview'
import Animation from '../../pages/animation'
import Category from '../../pages/category'
import CheckForUpdates from '../../pages/checkUpdates'
import CleearDatabase from '../../pages/clearDatabase'
import CustomizedTemplate from '../../pages/customizedTemplate'
import Element from '../../pages/element'
import Formats from '../../pages/formats'
import History from '../../pages/history'
import ImportAssets from '../../pages/importAssets'
import PSD from '../../pages/psd'
import Render from '../../pages/render'
import Save from '../../pages/save'
import SaveProject from '../../pages/saveProject'
import FAQ from '../../pages/faq'
import Template from '../../pages/template'
import Timeline from '../../pages/timeline'
import Properties from '../../pages/properties'

import {
  Container
} from './styles'

export const menu = [
  {id: 1, name: 'Introduction', route: 'introduction', parent: null, component: <Introduction/>,  display: true},
  {id: 2, name: 'What is Milagro', route: 'whatismilagro', parent: null, component: <About/>, display: true},
  {id: 3, name: 'Sign up', route: 'signup', parent: null,  component: <Signup/>, display: true},
  {id: 4, name: 'Sign in', route: 'signin', parent: null, component: <Signin/>, display: true},
  {id: 5, name: 'Home', route: 'home', parent: null, component: <Home/>, display: true},
    {id: 6, name: 'Open File', route: 'home-openfile', parent: 5, display: false},
    {id: 7, name: 'New File', route: 'home-newfile', parent: 5, display: false},
    {id: 8, name: 'Creative lines', route: 'home-creativelines', parent: 5, display: false},
    {id: 9, name: 'Formats', route: 'home-formats', parent: 5, display: false},
    {id: 10, name: 'Output', route: 'home-output', parent: 5, display: false},
    {id: 11, name: 'Drop', route: 'home-drop', parent: 5, display: false},
    {id: 12, name: 'Tasks', route: 'home-tasks', parent: 5, display: false},    
  {id: 13, name: 'Stage', route: 'stage', parent: null, component: <Stage/>, display: true},
  {id: 14, name: 'Preview', route: 'preview', parent: null, component: <Preview/>, display: true},
  {id: 15, name: 'Formats and creative lines', route: 'formats-creatives', parent: null, component: <Formats/>, display: true},
    {id: 16, name: 'Expandable formats', route: 'formats-expandable', parent: 15, display: false},
    {id: 17, name: 'Asterisk', route: 'formats-asterisk', parent: 15, display: false},
    {id: 18, name: 'Icons', route: 'formats-icons', parent: 15, display: false},
    {id: 19, name: 'Creative line', route: 'formats-creativeline', parent: 15, display: false},
  {id: 20, name: 'Import assets', route: 'importassets', parent: null, component: <ImportAssets/>, display: true},
    {id: 21, name: 'Importing mlgs', route: 'importing-mlgs', parent: 20, display: false},
    {id: 22, name: 'Importing a psd', route: 'importing-psd', parent: 20, display: false},
    {id: 23, name: 'Importing mutiple psds', route: 'importing-multiple-psds', parent: 20, display: false},
    {id: 24, name: 'Import image', route: 'importing-image', parent: 20, display: false},
    {id: 25, name: 'Importing a spritesheet', route: 'importing-spritesheet', parent: 20, display: false},
    {id: 26, name: 'Importing a video', route: 'importing-video', parent: 20, display: false},
    {id: 27, name: 'Importing audio clips', route: 'importing-audio', parent: 20, display: false},
    {id: 28, name: 'Updating assets', route: 'updating-assets', parent: 20, display: false},
    {id: 29, name: 'Clear banner', route: 'clear-banner', parent: 20, display: false},
  {id: 30, name: 'Timeline', route: 'timeline', parent: null, component: <Timeline/>, display: true},
    {id: 31, name: 'Time ruler', route: 'timeline-ruler', parent: 30, display: false},
    {id: 32, name: 'Layers', route: 'timeline-layers', parent: 30, display: false},
    {id: 33, name: 'Types of layers', route: 'timeline-type-layers', parent: 30, display: false},
    {id: 34, name: 'Visibility', route: 'timeline-visibility', parent: 30, display: false},
    {id: 35, name: 'Name', route: 'timeline-name', parent: 30, display: false},
    {id: 36, name: 'Order', route: 'timeline-order', parent: 30, display: false},
    {id: 37, name: 'Delete layer', route: 'timeline-delete-layer', parent: 30, display: false},
    {id: 38, name: 'Play', route: 'timeline-play', parent: 30, display: false},
    {id: 39, name: 'Preview', route: 'timeline-preview', parent: 30, display: false},
    {id: 40, name: 'Layer anatomy', route: 'timeline-layer-anatomy', parent: 30, display: false},
  {id: 41, name: 'Properties', route: 'properties', parent: null, component: <Properties/>, display: true},
    {id: 42, name: 'Format', route: 'properties-format', parent: 41, display: false},
    {id: 43, name: 'Time', route: 'properties-time', parent: 41, display: false},
    {id: 44, name: 'Loop', route: 'properties-loop', parent: 41, display: false},
    {id: 45, name: 'Border', route: 'properties-border', parent: 41, display: false},
    {id: 46, name: 'Size', route: 'properties-size', parent: 41, display: false},    
  {id: 47, name: 'Element', route: 'element', parent: null, component: <Element/>, display: true},
    {id: 48, name: 'Preview', route: 'element-preview', parent: 47, display: false},
    {id: 49, name: 'Category', route: 'element-category', parent: 47, display: false},
    {id: 50, name: 'Anchor-point', route: 'element-anchor-point', parent: 47, display: false},
  {id: 51, name: 'Animation', route: 'animation', parent: null, component: <Animation/>, display: true},
    {id: 52, name: 'Keyframe', route: 'animation-keyframe', parent: 51, display: false},
    {id: 53, name: 'Keyframe parameters', route: 'animation-keyframe-parameters', parent: 51, display: false},
    {id: 54, name: 'Add property', route: 'animation-add-property', parent: 51, display: false},
    {id: 55, name: 'Ease', route: 'animation-ease', parent: 51, display: false},
    {id: 56, name: 'Animation time', route: 'animation-time', parent: 51, display: false},
    {id: 57, name: 'Presets', route: 'animation-presets', parent: 51, display: false},
    {id: 58, name: 'Applying a preset', route: 'animation-applying-preset', parent: 51, display: false},
    {id: 59, name: 'Altering a preset / converting to keyframe', route: 'animation-altering-preset', parent: 51, display: false},
    {id: 60, name: 'Deleting a preset', route: 'animation-deleting-preset', parent: 51, display: false},
    {id: 61, name: 'Selection of keyframes and presets', route: 'animation-selection-keyframes', parent: 51, display: false},
    {id: 62, name: 'Selection box', route: 'animation-selection-box', parent: 51, display: false},
    {id: 63, name: 'Copy and paste keyframes and/or presets', route: 'animation-copy-paste-keyframe', parent: 51, display: false},
  {id: 64, name: 'Category', route: 'category-info', parent: null, component: <Category/>, display: true},
    {id: 65, name: 'What are they?', route: 'category-what-are-they', parent: 64, display: false},
    {id: 66, name: 'Types', route: 'category-types', parent: 64, display: false},    
  {id: 67, name: 'Psd', route: 'psd', parent: null, component: <PSD/>, display: true},
    {id: 68, name: 'Psd naming standards', route: 'psd-naming-standards', parent: 67, display: false},
    {id: 69, name: 'Naming layers', route: 'psd-naming-layers', parent: 67, display: false},
    {id: 70, name: 'Useful information', route: 'psd-useful-info', parent: 67, display: false},
    {id: 71, name: 'Folder groups behavior', route: 'psd-group', parent: 67, display: false},
    {id: 72, name: 'Cropping', route: 'psd-cropping', parent: 67, display: false},
  {id: 73, name: 'Template', route: 'template', parent: null, component: <Template/>, display: true},
    {id: 74, name: 'Native templates', route: 'template-native', parent: 73, display: false},
  {id: 75, name: 'Customized template', route: 'custom-template', parent: null, component: <CustomizedTemplate/>, display: true},
    {id: 76, name: 'Generic template', route: 'generic-template', parent: 75, display: false},
    {id: 77, name: 'Customized template', route: 'customized-template', parent: 75, display: false},
    {id: 78, name: 'Template of complex pieces', route: 'template-complex', parent: 75, display: false},
  {id: 79, name: 'History', route: 'history', parent: null, component: <History/>, display: true},
  {id: 80, name: 'Save', route: 'save', parent: null, component: <Save/>, display: true},
  {id: 81, name: 'Save project', route: 'saveproject', parent: null, component: <SaveProject/>, display: true},  
  {id: 82, name: 'Finishing / render / input', route: 'finalization', parent: null, component: <Render/>, display: true},
    {id: 83, name: 'Type', route: 'finalization-type', parent: 82, display: false},
    {id: 84, name: 'Comment', route: 'finalization-comment', parent: 82, display: false},
    {id: 85, name: 'Generate pack', route: 'finalization-generate-pack', parent: 82, display: false},
  {id: 86, name: 'Clear database', route: 'cleardatabase', parent: null, component: <CleearDatabase/>, display: true},
  {id: 87, name: 'Check for updates', route: 'checkupdates', parent: null, component: <CheckForUpdates/>, display: true},
    {id: 88, name: 'Templates - download', route: 'checkupdates-templates', parent: 87, display: false},
    {id: 89, name: 'Shortcuts', route: 'checkupdates-shortcuts', parent: 87, display: false},
  {id: 90, name: 'FAQ', route: 'faq', parent: null, component: <FAQ/>, display: true}
  ]

let activeid = 1
let childActiveid = 0
let search = false

export function SideMenu () {
  const [,setActive] = useState(null)
  const [listmenu, setListmenu] = useState(menu)
  const [searchmenu, setSearchmenu] = useState(false)

  const handleClickButton = (evt) => {
    const elementId = parseInt(evt.target.id.replace('item_menu_',''))
    var body = document.querySelector('#body-list')
    var scrollPage = document.querySelector('html')
    
    setActive(elementId)
    let menuList = null
    for (const el of listmenu) {
      if (elementId === el.id) {
        if (!el.parent) {
          activeid = el.id
          childActiveid = 0
          const element = document.getElementById(el.route)
          body.scrollTo(0, element.offsetTop - scrollPage.scrollTop)
          break
        } else {
          childActiveid = el.id
          const element = document.getElementById(el.route)
          body.scrollTo(0, element.offsetTop - scrollPage.scrollTop)
        }
      }
    }
    if (search) {
      document.getElementById('search-menu').value = ''
      menuList = JSON.parse(JSON.stringify(menu))
      setListmenu(menu)
    } else menuList = JSON.parse(JSON.stringify(listmenu))
      for (const el of menuList) {
        if (el.parent === activeid) {
          el.display = true
      } else if (el.parent) el.display = false
      setListmenu(menuList)
      setActive(evt.target)
    }
  }

  const handleChange = (evt) => {
    var width = window.innerWidth
    const input = evt.target.value.toLowerCase()
    if (width > 767) {
      setSearchmenu(false)
      let menuList = JSON.parse(JSON.stringify(listmenu))
      for (const m of menuList) {
        const index = m.name.toLowerCase().indexOf(input)
        if (input) {
          search = true
          if (index >= 0) m.display = true
          else m.display = false
        } else {
          menuList = JSON.parse(JSON.stringify(menu))
          search = false
        }
      }
      setListmenu(menuList)
    } else {
      let menuList = JSON.parse(JSON.stringify(listmenu))
      for (const m of menuList) {
        const index = m.name.toLowerCase().indexOf(input)
        if (input) {
          search = true
          if (index >= 0) m.display = true
          else m.display = false
          setSearchmenu(true)
        } else {
          setSearchmenu(false)
        }
      }
      setListmenu(menuList)
    }
  }

  return (
    <Container>
      <div id ='title-menu' className='help-menu'>
        <span>
          Milagro help center
        </span>
        <div className='search-field'>
          <input type='text' name='search' id='search-menu' placeholder='Search Milagro Support' onChange={handleChange}/>
          <div className='search-result' style={{display: searchmenu ? 'flex' : 'none'}}>
            {listmenu.map(item => (
              <div key={Math.random()} className='search-result-items'>
                <a style={{textDecoration: 'none'}} href={'#' + item.route}> 
                  <div
                    onClick={() => {
                      setSearchmenu(false)
                      document.getElementById('search-menu').value = ''
                    }}
                    style={{display: item.display ? 'flex' : 'none',
                    padding: item.display ? '10px 10px 10px 10px' : '0px',
                    color: '#242944'
                    }}>
                      { item.name }
                  </div>
                </a>
              </div>
            ))}
          </div>
          <button className='button-icon'><img src={ SearchIcon } alt='search'/></button>
        </div>
      </div>
        <div className='menu-wrapper'>
          <div id='side-menu' className='menu-items'>
            {listmenu.map(item => (
              <a key={Math.random()} style={{textDecoration: 'none'}}> 
                <div
                  id={'item_menu_' + item.id}
                  onClick={handleClickButton} 
                  style={{display: item.display ? 'flex' : 'none',
                  paddingLeft: item.parent ? '60px' : '25px',
                  width: item.parent ? '237px' : '272px',
                  color: ((activeid === item.id) || (childActiveid === item.id)) ? '#f34b5e' : '#242944',
                  background: (!childActiveid && activeid === item.id) ? '#f0f5f9' : childActiveid === item.id ? '#f0f5f9' : 'white'
                  }}>
                    { item.name }
                </div>
              </a>
            ))}
          </div>
        </div>
    </Container>
  )
}

export default { SideMenu, menu }