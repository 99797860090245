import React from 'react'

function PSD () {
  return (
    <div className='card' >
      <h2>
        PSD
      </h2>
      <p>
        This feature greatly helps during the production of a piece.
      </p>
      <p>
        When importing a PSD file, Studio inserts its elements automatically in the categories,
        basing itself on the names of the layers to do so.
      </p>
      <p>
        OBS: Following this naming standard is not mandatory. However, it really helps animating and replicating.
      </p>
      <h3 id='psd-naming-standards'>
        PSD naming standards
      </h3>
      <h3>
        Animation Cascade
      </h3>
      <p>In order for Milagro to create an animation cascade, each step of the banner must be placed inside a folder.</p>
      <p>The folder must be named as follows:</p>
      <li>
        “01”for the first step of the banner
      </li>
      <li>
        “02” for the second step of the banner
      </li>
      <li>
        And so on
      </li>
      <br></br>
      <p>Any other naming will turn the folder into a group in the timeline.</p>
      <h3 id='psd-naming-layers'>
        Naming layers
      </h3>
      <p>
        There is no rule for PSD layer naming. However, naming them in a standardized way allows the
        automatic categorization of them, and it also helps bridging the animation from one banner to the other.
      </p>
      <br></br>
      <p>Each layer must be named according to a specific order and naming:</p>
      <p style={{marginLeft: '40px'}}>
        1. Put the step the layer is in:
        <br></br>
        s1 ( as in step 1)
        <br></br>
        s2 ( as in step 2)
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        2. Put an underline “_” between step e category:
        <br></br>
        s1_
        <br></br>
        s2_
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        3. Add the category in which the element fits
        <br></br>
        s1_txt
        <br></br>
        s2_txt
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        4. Add a number to help organizing them:
        <br></br>
        s1_txt1
        <br></br>
        s2_txt2
      </p>
      <br></br>
      <p>Some names (categories) are default to layers</p>
      <li>
        “bg” - used for backgrounds.
      </li>
      <li>
        “txt” - used for texts.
      </li>
      <li>
        “img” - used for images in general.
      </li>
      <li>
        “logo” used for logos.
      </li>
      <li>
        “pack” - used for packs (in case there is any).
      </li>
      <li>
        “cta” - used for buttons.
      </li>
      <li>
        “logo” - used for product logo
      </li>
      <br></br>
      <p>In case layer naming follows no standard, the elements will be put in category called <b>"default"</b>.</p>
      <h3 id='psd-useful-info'>
        Useful information
      </h3>
      <li>
        Any layer in"layer comps" will be raster.
      </li>
      <li>
        Use 8 bit files.
      </li>
      <li>
        Do not use blend mode. (We decided to block tue use of blend mode due to compatibility issues with IE).
      </li>
      <li>
        Do not use Artboard.
      </li>
      <li>
        Do not use Timeline.
      </li>
      <li>
        Do not use Layer Comps.
      </li>
      <h3 id='psd-group'>
        Folder groups behavior
      </h3>
      <p>All PSD folders behave in a technical way, except one.</p>
      <p>If you name every animation step as “01”, “02” and so on, Milagro will automatically put a timeline together, with
        the opening and closing of each layer, performing the opening of step 01 and then its closing. This folder must be on
        the first level of PSD layers.
      </p>
      <br></br>
      <p>Milagro will create a group of layers of any other folder that has not been named under this hierarchy level.</p>
      <p>For a better experience, we suggest working with the standard color profile: sRGB IEC61966-2.1</p>
      <p>In case the file does not follow it, convert the file. That will assure no color changing happens during production.
      </p>
      <br></br>
      <p>On photoshop, go to EDIT>Convert to profile</p>
      <p>Select standard sRGB IEC61966-2.1</p>
      <h3 id='psd-naming-cropping'>
        Cropping
      </h3>
      <p>When creating a campaign, it is important to watch out for the quantity of images being used. Banners normally have a
        low size limit. Cropping files is a good idea, since it considerably reduces the size of files.</p>
    </div>
  )
}

export default PSD
