import React from 'react'

function ClearDatabase () {
  return (
    <div className='card' >
      <h2>Clear Database</h2>
      <p>The software might eventually crash</p>
      <p>In case that happens, the menu bar If it happens, there is a button in the menu bar that clears the software
        registration. Use it only in case something does not work properly.</p>
      <p>Go to Milagro Studio>Clear database</p>
    </div>
  )
}

export default ClearDatabase
