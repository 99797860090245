import React from 'react'
import ImportPsdImg from '../assets/ImportPSD.png'
// import SonicGif from '../assets/Sonic.gif'

function ImportAssets () {
  return (
    <div className='card' >
      <h2>
        Import assets
      </h2>
      <p>
        The most efficient way of working with Milagro Studio is to use a .psd file and import the entire key art.
        However, it is also possible to import images individually.
      </p>
      <h3 id='importing-mlgs'>
        Importing MLG’s
      </h3>
      <p>
        Click on the green button to import a MLG file.
      </p>
      <p>
        It is important that the imported file matches the dimensions of the file it’s being imported to.
      </p>
      <br></br>
      <p>
        Friendly reminder: importing a file is different than opening a file.
        When the user imports a .mlg file to another one that is empty,
        all the settings of the imported file will be replaced by the specs of the current banner.
      </p>
      <h3 id='importing-psd'>
        Importing a PSD.
      </h3>
      <img src={ImportPsdImg} alt='import-psd-img' />
      <p>
        When the user works with a very well organized c.psd file, building the timeline happens
        automatically: reading time will already be set and ready to receive the animation templates.
      </p>
      <br></br>
      <p>
        In order to be imported, the file must not contain layer blend modes, art boards nor animations.
      </p>
      <br></br>
      <p>
        Since Milagro works with 8bit files, we suggest that the user adapts the .psd file to our production template in order
        to fully enjoy the software.
      </p>
      <br></br>
      <p>
        Below, you will find further explanation on how the templates work, as well as download links.
      </p>
      <p>
        Milagro performs some steps during the importing of a file.
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        1 - In case the user imports a file straight from Milagro with no previous creation of a file,
        the software will generate one with the same dimensions of the .psd file and default settings.
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        2 - To improve banner size, separate the images as follows:
   
        <li>Images with transparency are saved as PNG.</li>

        <li>Images without transparency are saved as JPG.</li>

        <li>Shapes and curves are saved as vector (SVG).</li>
        <br></br>
      </p>
      <p style={{marginLeft: '40px'}}>
        3 - Create a layer on the timeline to each PSD layer and use the same names.
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        4 - Place each image on stage according to the .psd file
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        5 - First PSD folder level named correctly is used on the development of a base animation: the opening/closing object
        cascade on the stage/timeline
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        6 - Apply an opening/closing animation preset in all layers.
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        7 - Each PSD folder which is not on the first level is converted into a composition with all the layers applied on
        this structure, following the same structure used on the PSD.
      </p>
      <br></br>
      <p style={{marginLeft: '40px'}}>
        8 - A 1px black edge is added around the piece. It happens because most part of the portals demand so.
      </p>
      <br></br>
      <p>
        Friendly reminders
      </p>
      <br></br>
      <p>
        PSD layers with deactivated “view” function will not be imported. Animation cascade will only be automated after the
        creation of folders on Milagro’s template.
      </p>

      <h3 id='importing-multiple-psds'>
        Importing multiple PSDs
      </h3>
      <p>
        This is a feature that allows the user to import multiple PSD files at the same time. If there are open blank file
        tabs open and in case they match the dimensions of the files, Milagro will populate suchtabs.
      </p>
      <br></br>
      <p>
        In case there is none, Milagro will create a new tab.
      </p>
      <p>
        To access this feature, go to <b>File > Import multiple PSD</b>
      </p>

      <h3 id='importing-image'>
        Import image
      </h3>
      <p>
        To import an image, use the menu bar. FILE>IMPORT. A layer with this image will be created on the timeline.
      </p>
      <br></br>
      <p>
        Supported image formats
      </p>
      <p>
        <li>.png</li>
        <li>.jpg</li>
        <li>.gif</li>
        <li>.gif (animated)</li>
        <li>.svg (static)</li>
      </p>

      <h3 id='importing-spritesheet'>
        Importing a spritesheet
      </h3>
      <p>
        Spritesheet consists on changing an image by another one almost similar to the first one, but with small differences.
        Like a walking character, for example: the first image shows a the character in standing position, the next image
        shows the character moving their leg and so on. When these pictures are animated, it gives the impression the
        character is actually moving. It is an animation technique that started with videogames and it keeps being used.
      </p>
      <br></br>
      <p>
        This feature is ideal to solve limitation issues when animating in HTML. For example, when programming an animation to
        complex to be done in HTML and that would be used in loop.
      </p>
      {/* <img src={SonicGif} alt='sonic-gif' /> */}
      <p>
        Friendly reminder:
      </p>
      <p>
        Use spritesheets wisely. They are made with a high quantity of images and that can cause media size issues.
      </p>
      <br></br>
      <p>
        To import a spritesheet, go to the menu and select the option <b>File > Import > Spritesheet</b>.
      </p>
      <p>
        Select all images and click on <b>OPEN</b>.
      </p>
      <br></br>
      <p>
        A new layer with the color orange and a new icon will be generated.
      </p>
      <p>
        To adjust the animation’s time and starting point, just edit the position of the keyframes, like you would in a
        standard layer.
      </p>
      <br></br>
      <p>
        The ELEMENT tab preview comes up animated.
      </p>
      <p>
        Sprite has got an exclusive window in which is possible to select the quantity of times the loop will happen.
      </p>
      <br></br>
      <p>
        It is also possible to choose the quantity of steps, but that may cause problems to the animation if it s not done
        properly.
      </p>
      <p>
        The layer of the Spritesheet has exclusive color and keyframe styles which represents the loop time. To help users get
        a better view of the process, the quantity of loops interfere on the size of this box.
      </p>
      <h3 id='importing-video'>
        Importing a video
      </h3>
      <p>
        Importing a video is a new feature to the tool and it is under constant improvement. For short clips, it represents a
        better choice than using spritesheets, because it is possible to perform a better optimization of its size.
      </p>
      <br></br>
      <p>
        On the other hand, the size of the video cannot be optimized in Milagro. So, it is necessary to perform the
        optimization before importing the video.
      </p>
      <br></br>
      <p>
        The estimate size sums the video and the image sizes.
      </p>
      <h3 id='importing-audio'>
        Importing Audio clips
      </h3>
      <p>
        For video output only
      </p>
      <h3 id='updating-assets'>
        Updating Assets
      </h3>
      <p>
        It is possible to update images from a used PSD file, which greatly helps when the text or the art has been altered
      </p>
      <p>
        To do so, go to <b>File > Import / Update PSD</b>.
      </p>
      <br></br>
      <p>
        Milagro will compare all images that have the same name as the PSD file with the images in MLG files and will perform
        changes according to the following:
      </p>
      <li>Image change</li>
      <li>Position change</li>
      <li>Dimension change</li>
      <li>In case the image is in a group, (that came from a PSD file), all group dimensions will be altered</li>
      <br></br>
      <p>
        Friendly reminder:
      </p>
      <p>
        The update file doesn’t necessarily have to be the same one of the production. What really matters is that the names
        of the altered objects match the MLG’s.
      </p>
      <h3 id='clear-banner'>
        Clear Banner
      </h3>
      <p>
        To reset the banner and start a new production, go to FILE>CLEAR BANNER. This will erase all banner and its history.
      </p>
    </div>
  )
}

export default ImportAssets
