import React from 'react'

function Save () {
  return (
    <div className='card' >
      <h2>Save</h2>
      <p>To save a file, either use the menu bar or the shortcut “command + S”.</p>
      <p>Milagro works with its own extension, “.mlg”.</p>
      <br></br>
      <p>When saving a file, a folder with structures that organize the project is created in the desktop. This file may be
        used in other campaigns or as an animation template. A confirmation message will appear in case this is the first time
        the file is being saved. The message will show the way to the file.</p>
    </div>
  )
}

export default Save
