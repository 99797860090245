import React, { useState, useEffect }  from 'react'
import LogoMilagro from '../../assets/logo_milagro.jpg'
import Arrow from '../../assets/redArrow.png'
import CTAArrow from '../../assets/cta-arrow.png'
import MenuIcon from '../../assets/menu_icon.png'

import {
  Container
} from './styles'

function TopBar () {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (menuOpen) document.addEventListener('click', handleCloseMenu)
    else document.removeEventListener('click', handleCloseMenu)
  })
  
  const handleCloseMenu = (evt) => {
    if (evt.target.id !== 'menu-mobile-img' && evt.target.id !== 'menu-mobile') {
      if (menuOpen) {
        setMenuOpen(false)
      }
    }
  }

  const handleClickMenu = () => {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
    }
  }

  return (
    <Container>
      <div className='logo'>
			  <a title='go to home' href='/'><img src={ LogoMilagro } alt='' /></a>
      </div>
      <nav className='menu'>
			<ul>
				<li><a className='index' href='https://www.milagro.cc/'>Home</a></li>
				<li><a className='features' href='https://www.milagro.cc/features.html'>Features</a></li>
				<li><a className='gallery' href='https://www.milagro.cc/gallery.html'>Gallery</a></li>
				<li><a className='plans' href='https://www.milagro.cc/pricing.html'>Plans</a></li>
				<li><a className='faq' href='https://www.milagro.cc/faq.html'>FAQ</a></li>
				<li><a className='contactMenu' href='https://www.milagro.cc/contact.html'>Contact</a></li>
				{/* <li>
          <a href='https://suite.milagro.cc/' rel='noopener noreferrer' target='_blank'>
            <button className='button'>
              <span>
                Login
              </span>
							<img className='arrow-button' src={ Arrow } alt='' />
							<img className='arrow-button' src={ CTAArrow } alt='' />
						</button>
          </a>
        </li> */}
			</ul>
		</nav>
    <div className='menu-mobile' onClick={handleClickMenu}>
      <img id='menu-mobile-img' src={MenuIcon} alt='menumobile' ></img>
    </div>
      <nav id='menu-mobile' className='menu-open' style={{display: menuOpen ? 'flex' : 'none'}}>
        <ul>
          <li><a className='index' href='https://www.milagro.cc/'>Home</a></li>
          <li><a className='features' href='https://www.milagro.cc/features.html'>Features</a></li>
          <li><a className='gallery' href='https://www.milagro.cc/gallery.html'>Gallery</a></li>
          <li><a className='plans' href='https://www.milagro.cc/pricing.html'>Plans</a></li>
          <li><a className='faq' href='https://www.milagro.cc/faq.html'>FAQ</a></li>
          <li><a className='contactMenu' href='https://www.milagro.cc/contact.html'>Contact</a></li>
          <li><a href='https://suite.milagro.cc/' rel='noopener noreferrer' target='_blank'>
            <button className='button'>
                Login
                <img src={ Arrow } alt='' />
                <img src={ CTAArrow } alt='' />
              </button>
            </a>
          </li>
        </ul>
      </nav>
  </Container>
  )
}

export default TopBar