import React from 'react'
import SigninImage from '../assets/SignIn.png'

function Signin () {
  return (
    <div className='card' >
    <h2>
      Sign In
    </h2>
    <p>
    To begin using the software, type in your username and create a password. Then, click on login.
    </p>
    <p>
    In case the login or password is incorrect, an error message will pop up.
    </p>
    <p>
    If the problem continues, reach our support group and have you password reset. In case you still do not have an account, get in touch with us through the website <a href='www.milagro.cc'>www.milagro.cc</a>
    </p>
    <img src={SigninImage} alt="signin"/>
  </div>
  )
}

export default Signin
