import React from 'react'
import NewFile from '../assets/NewFile.png'
import NewFile2 from '../assets/NewFile_2.png'
import OpenFile from '../assets/OpenFile.png'
import Drop from '../assets/Drop.png'
import Tasks from '../assets/Tasks.png'

function Home () {
  return (
    <div className='card'>
    <h2 style={{marginBottom: '15px'}}>
      Home
    </h2>
    <h3 id='home-openfile' style={{marginTop: '0px'}}>
      Open File
    </h3>
    <img src={OpenFile} alt="newfile"/>
    <p>
      Milagro has its own file format extension - “.mlg”. To open these files, click on the <b>OPEN FILE </b>button 
      on the menu bar. 
    </p>
    <p style={{marginBottom: '20px'}}>
      Double clicking on one of these files will also open the Studio. 
    </p>
    <p>
      Milagro also opens PSD files.
    </p>
    <h3 id='home-newfile'>
      New File
    </h3>
    <img src={NewFile} alt="newfile"/>
    <img src={NewFile2} alt="newfile2"/>
    <p>
      This is where you set the formats and creatives to be produced. It is worth pointing that a correct initial setup 
      makes the pieces’ QA a lot easier.
    </p>
    <p>
      You will have to set a creative, the dimension and the output format of the file. However, you can start a piece only 
      by setting the dimensions.
    </p>
    <p>
      There are some shortcuts which help production. You will find them at the NEW FILE screen. When you click 
      the formats, a tab with CREATIVE, FORMAT and OUTPUT is created automatically.
    </p>
    <h3 id='home-creativelines'>
      Creative Lines
    </h3>
    <p>
    It is common for a campaign to be made by more than a single creative line. Here you can set as many lines as 
    necessary, and each one of them will have it’s different formats to be produced. That’s why it is possible to 
    duplicate, rename and even delete files.
    </p>
    <p>
    In case you need to start a new creative line, click on the ADD CREATIVE button. However, if this is not so 
    relevant to your campaign, you can use Milagro’s default creative line.
    </p>
    <h3 id='home-formats'>
      Formats
    </h3>
    <p>
      To create a new banner or video, click the ADD BANNER button and fill the top fields. If you are working 
      on a expandable piece, also fill the bottom fields.
    </p>
    <h3 id='home-output'>
      Output
    </h3>
    <p>
    The same MLG file can have multiple outputs, being them HTML or even videos. Each box represents a file output 
    in the final closing.
    </p>
    <p>
      <br></br>
      <b>Content of each box.</b> (They may be altered later).
    </p>
      <li><b>Name: </b> name of the final file. </li> 

      <li><b>Output: </b> type of output. e.g: MP4, MOV, HTML DCM, HTML PORTAL, etc.</li>
 
    <li>
        <b>Zip: </b> In case the final output is compressed (common in html), you just have to select 
        the field. It will be taken into account at the file size estimate. This field is selected automatically, 
        according to the closing. However, it may be edited as needed.
    </li>

     <li><b>Portal: </b> name of the portal where the piece will be placed. </li>


      <li><b>Time: </b> media animation time.</li>

      <li><b>Size: </b> allowed size limit.</li>

      <li><b>Backup: </b> HTML banners need a backup piece. Insert file size limit here.</li>

    <br></br>
    <p>
      Note: expandable formats have two fields for size and time.
    </p>
    <p>
      - In case it is a single file, fill only the first field.
    </p>
    <p>
      - In case they are separate files (an HTML for the retracted one and a different HTML for 
      the expanded one), fill both fields.
    </p>
    <h3 id='home-drop'>
      Drop
    </h3>
    <img src={Drop} alt="drop"/>
    <p>
    This feature was developed to speed up the production process. It is possible to drag a .MLG or a .PSD to the 
    HOME and Milagro will open this file.
    </p>
    <br></br>
    <p>
    Actually, several files may be dragged at the same time, even if they do not have the same extension. 
    For example, you may import 1 MLG and 5 PSDs at once
    </p>
    <br></br>
    <p>
    In case you drag a PSD, a HTML will be created with the exact same dimensions.
    </p>
    <br></br>
    <p>
    Any further setup may be done by clicking on FILE>EDIT PROJECT . In a situation like this, Milagro will open
     the MLG file and create 5 new ones.
    </p>
    <h3 id='home-tasks'>
      Tasks
    </h3>
    <img src={Tasks} alt="tasks"/>
    <p>
      Milagro Studio connects via API with all project management softwares used by the professional to receive 
      the tasks to be developed.
    </p>
    <br></br>
    <p>
      Clicking on any of these tasks will open Format window. All formats to be produced and all the information 
      necessary will already be provided by then.
    </p>
  </div>
  )
}

export default Home
