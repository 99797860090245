import React from 'react'
import TemplateImg from '../assets/template.png'

function Template () {
  return (
    <div className='card' >
      <h2>Template</h2>
      <p>Along with closing, this feature is the one that brings the most advantages when producing animations, for it allows
        the user to apply complex animations to all formats with just a few clicks.</p>
      <p>It is located on the second tab.</p>
      <h3 id='template-native'>Native Templates</h3>
      <img src={TemplateImg} alt='template' />
      <p>O Studio has got several animation templates that ease the production. It is possible to see their animation with a
        simple mouse over.</p>
      <p>A single click on the template allows the user to change the whole animation of the project, changing the animations
        on the timeline and enabling the real time view of the project.</p>
      <br></br>
      <p>Native templates change the animation of the categories. It means the layers must be categorized so this feature will
        work properly.</p>
    </div>
  )
}

export default Template
