import React from 'react'
import LogoMilagro from '../../assets/logo-milagro-white.png'
import LetterIcon from '../../assets/icon-cta-letter.png'
import LetterWhiteIcon from '../../assets/icon-cta-letterWhite.png'
import FaceIcon from '../../assets/icon-face.png'
import LinkedinIcon from '../../assets/icon-linkedin.png'
import YoutubeIcon from '../../assets/icon-youtube.png'
import StartupIcon from '../../assets/statup-selo.png'
import {
  Container
} from './styles'

function Footer () {
  return (
    <Container>
      <div className='subscribe-container'>
        <span className='footer-followus'>Follow us for further information</span>
          <label htmlFor='email-footer' title='Type your email here'></label>
          <input type='email' name='EMAIL' id='email-footer' placeholder='yourname@domain.com'/>
            <div className='email-footer-sended d-none'>
              <p>Thanks!!!</p>
            </div>
            <button className='cta-subscribe'>
              Subscribe
              <img src={LetterIcon} alt=''/>
              <img src={LetterWhiteIcon} alt=''/>
            </button>
        </div>
        <div className='list-container'>
            <div className='sections-list'>
                <div className='section-a'>
                    <img src={LogoMilagro} alt=''/>
                    <p>Milagro is the world’s <span>only design software</span> that saves up to 70% of campaign creating
                        time. Its <span>cutting edge technology</span> boosts the production of pieces for digital media through
                        <span>autonomization</span>, so that you can focus on what really matters: <span>Creativity</span>.
                    </p>
                    <div className='social-medias'>
                        <a href='https://www.facebook.com/milagroapp/' rel='noopener noreferrer' target='_blank'><img src={FaceIcon} alt=''/></a>
                        <a href='https://www.linkedin.com/company/milagroapp/' rel='noopener noreferrer' target='_blank'><img src= {LinkedinIcon} alt=''/></a>
                        <a href='https://www.youtube.com/channel/UCb8fY1GKYDPOErMur0FSozQ' rel='noopener noreferrer' target='_blank'><img src={YoutubeIcon} alt=''/></a>
                    </div>
                      <a href='https://www.startupgrind.com' rel='noopener noreferrer' target='_blank'><img className='startup-selo' src={StartupIcon} alt=''/></a>
                </div>
            </div>
        </div>
      <span className='text-copyright'>© 2020 Milagro. All Rights Reserved.</span>
    </Container>
  )
}

export default Footer