import React from 'react'
import StageImg from '../assets/Palco.png'

function Stage () {
  return (
    <div className='card' style={{marginTop: '100px'}}>    
    <h2>
      Stage
    </h2>
    <p>
      The software’s main screen
    </p>
    <img src={StageImg} alt="stage"/>
  </div>
  )
}

export default Stage
