import React from 'react'

function SaveProject () {
  return (
    <div className='card'>
      <h2>Save Project</h2>
      <p>It works as a gathering of all the open tabs and saves them in a single directory. This is good for organizing and
        copying the project.</p>
    </div>
  )
}

export default SaveProject
