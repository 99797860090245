import React from 'react'
import RenderImg from '../assets/render.png'

function Nome () {
  return (
    <div className='card' id='nome' >
      <h2>Finishing / Render / Output</h2>
      <p>Milagro gives you peace when wrapping up your campaigns.</p>
      <p>There is no need for adjusting the campaign to the specs. All the user has to do is select the output and Milagro
        will do all the work. Once the output setting has been made, click on “pack”. pack.png</p>
      <br></br>
      <p>A list of open pieces will pop up. Select the ones that must be closed and click on <b>GENERATE PACK</b>.</p>
      <p>To close them all, click on <b>SELECT ALL</b>.</p>
      <p>To clean the selection, click on <b>CLEAR</b>.</p>
      <img src={RenderImg} alt='render' />
      <h3 id='finalization-type'>Type</h3>
      <p>This section helps controlling the type of delivery. After all, this options help closing pieces.</p>
      <li>
        New Pack
      </li>
      <li>
        Client Adjustments
      </li>
      <li>
        Company Adjustments
      </li>
      <h3 id='finalization-comment'>Comment</h3>
      <p>Use this functionality in case you want a comment to go to reports</p>
      <p>Ex: "Alteration of logo requested by the client".</p>
      <h3 id='finalization-generate-pack'>Generate Pack</h3>
      <p>Clicking on this button generates a folder on the desktop containing the way to the project and a compressed file
        with the whole campaign.</p>
      <li>
        A backup piece will be generated automatically.
      </li>
      <li>
        In case it is a compressed delivery, a compressed file will be generated.
      </li>
      <li>
        Adds all clicktags to the closings.
      </li>
      <li>
        Checks file size
      </li>
      <li>
        Milagro will let the user know if the piece is oversized.
      </li>
    </div>
  )
}

export default Nome
