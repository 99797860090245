import React from 'react'
import FormatsImg from '../assets/aba.gif'

function Formats () {
  return (
    <div className='card' >
      <h2>
        Formats &#38; Creative Lines
      </h2>
      <img src={FormatsImg} alt='formats-and-creative-lines' />
      <p>
        Above the project the user will find the format tabs.
        The text shown in the tab is the name of the MLG file.
      </p>
      <br></br>
      <p>
        Whenever there is a new file, the name shown refers to its dimensions.
      </p>
      <p>
        If there are files with the same name, a suffix will be temporarily added to them.
        It happens so the final name of the file suffers no alteration.
      </p>
      <br></br>
      <p>
        To access each file, simply click on its tab and check its properties and history.
      </p>
      <h3 id='formats-expandable'>
        Expandable Formats
      </h3>
      <p>
        Whenever the file presents unsaved changes, an asterisk will pop beside its name.
        As soon as the user saves the changes, the asterisk disappears.
      </p>
      <h3 id='formats-asterisk'>
        Asterisk
      </h3>
       <p>
          Whenever the file presents unsaved changes, an asterisk will pop beside its name. As soon as the user 
          saves the changes, the asterisk disappears.
       </p>
      <h3 id='formats-icons'>
        Icons
      </h3>
      <p>
        An exclamation icon on the right side of the file’s name means the piece does not follow
        the specs. User should check <b>size</b>, <b>length</b> and <b>empty file</b>. When the specs are ok, the icon
        will turn green.
      </p>
      <h3 id='formats-creativeline'>
        Creative line
      </h3>
      <p>
        Organize your project by using separate creative lines.
        To access them, use the dropdown menu on the left side of the preview.
      </p>
    </div>
  )
}

export default Formats
