import React from 'react'
import PropertiesImg from '../assets/Propriedades.png'
import LoopImg from '../assets/loop.png'
import SizeImg from '../assets/size.png'

function Properties () {
  return (
    <div className='card'>
      <h2>
        Properties
      </h2>
      <img src={PropertiesImg} alt='properties' />
      <h3 id='properties-format'>
        Format
      </h3>
      <p>
        Describe the file’s dimension.
      </p>
      <h3 id='properties-time'>
        Time
      </h3>
      <p>
        In case the user is working with HTML, this feature shows the time limit of the animation. In case of videos, it shows
        the length it will have once it’s finished.
      </p>
      <p>
        OBS: In case there is an animation after the time limit, it will crop the video.
      </p>
      <h3 id='properties-loop'>
        Loop
      </h3>
      <p>
        For HTML deliveries, it is possible to configure the piece loop.It is possible to determine the quantity of loops or
        set it as infinite.
      </p>
      <p>
        It is possible to pause the banner after a loop in the middle of the piece. To do so, it is necessary to right click
        on the ruler in the timeline, under the time, and select ADD LOOP PAUSE
      </p>
      <br></br>
      <p>
        When a loop is added to the banner, a second time value appears beside default. This time is the sum of all the banner
        loops.
      </p>
      <p>
        In case the time of the piece exceeds the specs’ limits, the number will turn red, and the layer will show an
        exclamation icon that indicates incompatibility, which prevents the piece from being rendered.
      </p>
      <img src={LoopImg} alt='loop' />
      <h3 id='properties-border'>
        Border
      </h3>
      <p>
        Allows to configure thickness and color of the border.
      </p>
      <h3 id='properties-size'>
        Size
      </h3>
      <p>
        A feature that really helps speed up the production.
      </p>
      <p>
        This tool allows to reduce or increase the quality of the images in order to set the file within the media specs. The
        result is shown in real time, and it gives a size estimate of the file.
      </p>
      <br></br>
      <p>
        The user can increase or reduce the quality freely, since the key image is kept with 100% quality. This, there is no
        risk of reducing it too much.
      </p>
      <p>
        Closing types present a relevant variation to the file’s final size, because they determine whether the delivery is
        compressed. For that reason, the information is considered on the size estimate.
      </p>
      <br></br>
      <p>
        OBS: This is an estimate, so there might be differences. However, Milagro won’t allow the piece to bie finished
        oversized, since it would impede the delivery.
      </p>
      <br></br>
      <p>
        When there is more than one type of closing, it is possible to configure the output quality individually.
      </p>
      <p>
        When closing a file, Milagro will export an output to each type of closing, based on the selected quality. Video,
        image sequences and GIFs do not have size optimization. Yet ;
      </p>
      <img src={SizeImg} alt='size' style={{marginBottom: '0px'}}/>
    </div>
  )
}

export default Properties
