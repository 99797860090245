import styled from 'styled-components'

export const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 0;

  > .logo,
    .menu {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
  }

  .menu ul {
    text-align: right;
    display: flex;
    align-items: center;
  }

  .menu ul li {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    margin-right: auto;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }

  .menu ul li a {
    color: #242944;
    text-decoration: none;
    margin-right: 30px;
    float: left;
  }

  .menu ul li a:hover {
    color: #f34b5e;
  }

  .button {
    display: flex;
    width: 170px;
    height: 54px;
    text-decoration: none;
    align-items: center;
    font-size: 18px;
    -webkit-transition-duration: 0.4s;
    justify-content: center;
    /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    outline: none;
    background-color: white;
    position: relative;
    color: #f34b5e;
    border: 2px solid #f34b5e;
    border-radius: 100px;
  }

  .button img {
    margin-left: 13px;
  }

  .button span {
    height: 100%
  }

  .button img:last-child {
    position: absolute;
    right: 45px;
    opacity: 0;
  }

  .button:hover > img:first-child {
    opacity: 0;
    transition-duration: 0.4s;
  }

  .button:hover > img:last-child {
    opacity: 1;
    transition-duration: 0.4s;
  }

  .button:hover {
    background-color: #f34b5e;
    color: white;
  }

  .menu-mobile {
    display: none
  }

  @media (max-width: 1023px) {
    .menu ul li a {
    margin-right: 10px;
    }

    .logo {
      margin-left: 15px;
    }

    .arrow-button {
      display: none;
    }

    .button {
      width: 100px;
      justify-content: center;
    }
  
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 25px;

    .menu {
      display: none;
    }

    .logo {
      margin-left: 20px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .menu-mobile {
      display: flex;
    }

    .menu-open {
      justify-content: center;
      display: flex;
      width: 100%;
      height: 480px;
      position: absolute;
      top: 0px;
      left: 0px;
      background: white;
      z-index: 1;
    }

    ul {
      margin-top: 60px;
      margin-bottom: 40px;
      list-style-type: none;
      text-align: center;
      padding: 0px;
    }

    .menu-open li {
      margin-bottom: 20px;
    }

    .menu-open a {
      color: #242944;
      text-decoration: none;
      font-size: 24px;
      font-weight: bold;
      font-family: 'roboto';
    }

    .button {
      width: 145px;
    }
  }
`