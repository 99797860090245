import React from 'react'

function History () {
  return (
    <div className='card'>
      <h2>History</h2>
      <p>To access the history, go to MENU>ACTIONS. Using the shortcut “command + H” is also possible.</p>

      <p>All changes are listed, so it is possible to undo them.</p>

    </div>
  )
}

export default History
