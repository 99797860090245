import React from 'react';
import Topbar from './components/Topbar'
import { SideMenu } from './components/SideMenu'
import Body from './components/Body'
import Footer from './components/Footer'
import './App.css';

function App() {
  return (
    <div className='root-app'>
      <div className='top-bar'>
        <Topbar/>
      </div>
      <div className='center-container'>
        <div className='side-menu'>
          <SideMenu/>
        </div>
        <div className='body-container'>
          <Body/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
