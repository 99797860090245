import React from 'react'
import CategoryImg from '../assets/categoria.png'

function Category () {
  return (
    <div className='card' >
      <h2>Category</h2>
      <h3 id='category-what-are-they'>
        What are they?
      </h3>
      <p>
        To make the production of pieces a lot easier, Studio groups the elements in categories.
        It allows the user to change the animation of all the elements at once.
      </p>
      <h3>Ex.: 1</h3>
      <p>
        In case you have many images or text layers, they will all be grouped in the text category.
        Once the animation applied to the category is changed, all the elements in it will have their animations changed as
        well.
      </p>
      <p>Placing or removing layers from a category is as simple as a click and drag.</p>
      <img src={CategoryImg} alt='categoria'/>
      <h3 id='category-types'>
        Types
      </h3>
      <p>
        There are some default categories available in Studio. Each of them has a different color,
        to make it easier for the user to identify them on the timeline.
      </p>
      <li>
        Background
      </li>
      <li>
        Text
      </li>
      <li>
        Image
      </li>
      <li>
        Logo
      </li>
      <li>
        Pack
      </li>
      <li>
        CTA
      </li>
      <br></br>
      <p>Changing categories is made via drag-and-drop. When the category is changed, the animation also changes in the
        timeline.
      </p>
      <p>
        There are cases in which the layer does not fit a standard. In such cases, the
        layer will remain out of the category and it will have a different color.
      </p>
      <br></br>
      <p>Still, an uncategorized object may be animated with a preset.</p>
      <p>There is a preview in each category so the select animation can be seen by the user.</p>
      <p>A click on the CHANGE PRESET button grants access to the entire animation library.</p>
    </div>
  )
}

export default Category
