import styled from 'styled-components'

export const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .help-menu {
    min-height: 160px;
    background: #f34b5e;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .help-menu button {
    position: absolute;
    right: 15px;
    top: 16px;
    font-weight: bold;
    }

  .help-menu span {
    margin-top: 30px;
    margin-bottom: 15px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 23px;
    margin-left: 48px;
  }

  input {
    width: 259px;
    height: 46px;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 15px;
  }

  input::placeholder {
    color: #242944;
    opacity: 50%;
  }

  .search-field {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-items {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .menu-items a {
    margin-left: 25px;
  }

  .menu-items div {
    outline: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #242944;
    width: 212px;
    height: 50px;
    border-radius: 30px;
    border: 0;
    align-items: center;
    transition: 0.25s;
  }

  .button-icon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .item-button {
    background: white;
  }

  .item-button-actived {
      background: #f0f5f9;
  }

  .scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    margin-bottom: 25px;
  }

  .force-overflow {
    min-height: 450px;
  }

  #style-1::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  #style-1::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }

  .scroll-bar:hover {
    background-color: #d2d5d8;
  }

  .menu-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 33px 0px 33px 0px;
  }

  .menu-wrapper::-webkit-scrollbar {
      display: block;
      width: 10px;
    }

  .menu-wrapper::-webkit-scrollbar-thumb {
    background: #E5E7EA;
    border-radius: 5px 5px 5px 5px;
    padding: 20px 20px 20px 22px;
    height: 10px;
  }

  .right-container {
    width: 100%;
    background-color: aqua;
  }

  .body {
      display: flex;
      /* height: 1800px; */
  }

  @media (max-width: 767px) {
    .menu-wrapper {
      display: none
    }

    .search-result {
      width: 100%;
      max-height: 150px;
      position: absolute;
      background: white;
      flex-direction: column;
      overflow-y: scroll;
      scroll-behavior: smooth;
      padding-bottom: 10px;
      border: 1px solid black;
      z-index: 3;
    }

    .search-result::-webkit-scrollbar {
      display: block;
    }

    .search-result::-webkit-scrollbar-thumb {
      background: #E5E7EA;
      width: 1px;
      height: 50px;
      border-radius: 5px 5px 5px 5px;
    }

    .search-result-items {
      font-size: 16px;
      font-family: 'roboto'
    }

    input {
      width: 88vw;    
    }

    .help-menu span {
      margin-left: 6%;
    }
  }
`