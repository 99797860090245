import React from 'react'
import GroupImg from '../assets/grupos.png'
import TimelineImg from '../assets/timeline.png'

function Timeline () {
  return (
    <div className='card' >
      <h2>
        Timeline
      </h2>
      <h3 id='timeline-ruler'>
        Time ruler
      </h3>
      <p>
        The ruler above the timeline shows the time ins seconds.
      </p>
      <p>
        The white line shows the time in which the preview is visible.
      </p>
      <p>
        The timeline loop button works only on preview and does not apply to the piece.
      </p>
      <h3 id='timeline-layers'>
        Layers
      </h3>
      <p>
        Each element of the piece is connected to a layer. The name of these layers is the same of the PSD files. This, of
        course, is important to the organization and recognition of the image for replication.
      </p>
      <p>
        Layers have a large quantity of options and settings, and everything is visually simplified.
      </p>
      <h3 id='timeline-type-layers'>
        Types of Layers
      </h3>
      <li>
        <b>Standard</b>: Studio’s standard layer. It contemplates assets such as jpg, png, svg and gif, whether they are
        imported via .psd or individually.
      <br></br>
        The color of this layer depends on the category it belongs to.
      </li>
      <br></br>
      <li>
        <b>Group</b>: A group of layers, represented by the group icon.
        <br></br>
        The color of this layer depends on the category this layer belongs to.
        <br></br>
        To create a group, just select the layers to be grouped and use the keyboard shortcut “Command/Control + G”. You can
        also use the right click.
        <br></br>
        <br></br>
        To ungroup, use the shortcut “Command/Control + Shift + G”. You can also use the right click.
        <br></br>
        This layer does not have an image. It is used to animate a group of elements together or to mask them.
        <br></br>
        There is an arrow at the beginning of the layer which can be used to expand and retract the group.<br></br>
        This new group has stage dimensions and is positioned at the x0;y0.
        <br></br>
        <br></br>
        Groups created from a PSD have the same dimensions of the images inside them. Their mask is active by default, but
        it can be deactivated at the tab ELEMENT.
        <br></br>
        To customize the groups, there are colored lines that make it clear to the user where each object is.
      </li>
      <img src={GroupImg} alt='groups' />
      <li>
        <b>Sprite Sheet</b>: special layer for sequences of images in which is possible to change the time of the animation
        and the quantity of loops.
        <br></br>
        The color set for this layer is orange
      <br></br>
        It is possible to compress this layer with size optimization.
      </li>
      <br></br>
      <li>
        <b>Video</b>: special layer for the application of MP4 videos. Size optimization does not alter this type of assets,
        but the size estimate calculates the size of the video with the size of all other assets.
        <br></br>
        The color set for this layer is orange.
        <br></br>
        The best scenario is to import a video which size has already been optimized so that the HTML reaches the specs.
      </li>
      <br></br>
      <li>
        <b>Audio</b>: For video output only, it is possible to apply loops in the audio clip.
        The color set for this layer is dark green
      </li>
      <h3 id='timeline-visibility'>
        Visibility
      </h3>
      <p>
        The “eye” icon enables and disables the preview of the element on stage. The object can always be exported, even if it
        is disabled.
      </p>
      <h3 id='timeline-name'>
        Name
      </h3>
      <p>
        When importing any asset, its name is kept in Milagro, and it can be changed at any time.
      </p>
      <h3 id='timeline-order'>
        Order
      </h3>
      <p>
        It is possible to alter the order of the layers just by clicking and dragging them up or down. It has to be done one
        layer at a time.
      </p>
      <h3 id='timeline-delete-layer'>
        Delete layer
      </h3>
      <p>
        To delete a layer, just right click it and select the option “delete”
      </p>
      <h3 id='timeline-play'>
        Play
      </h3>
      <p>
        To press play on an animation, just click the button or push the spacebar. Shortcuts and can also be used for
        navigation.
      </p>
      <h3 id='timeline-preview'>
        Preview
      </h3>
      <p>
        To have an idea of the animation’s speed and ease, it is a good idea to generate a preview. To do so, use the shortcut
        “command+P”. It runs on Chrome.
      </p>
      <h3 id='timeline-layer-anatomy'>
        Layer anatomy
      </h3>
      <img src={TimelineImg} alt='timeline' />
      <p>
        There are three levels of opacity for layers that represent different states.
        <br></br>
        As the image shows, the space with the number has opacity set on 0. It meant the assets - video, image or audio - is
        not in this period of time
        <br></br>
        <br></br>
        The part with some transparency, represented by the number 2, means the object is on stage, but it is not animated.
        <br></br>
        The period without transparency indicates the object has been animated.
      </p>

    </div>
  )
}

export default Timeline
