import React from 'react'
import { menu } from '../SideMenu'
import {
  Container
} from './styles'

function Body (props) {

  return (
    <Container id="body-list">
      {menu.map(item => (
        <div 
          key={Math.random()}
          id={ item.route }
        >
          {item.component}
        </div>
      ))}
    </Container>
  )
}

export default Body