import React from 'react'
import KeyframesImg from '../assets/keyframes.png'
import GroupsImg from '../assets/grupos.png'

function CustomTemplate () {
  return (
    <div className='card' >
      <h2>Customized template</h2>
      <p>Just as much as the native templates, Milagro’s MLG file works as an animation template itself. It means the user can
        develop their own animation standards and reuse them multiple times.</p>
      <p>There are three ways of applying the animation from one piece on another.</p>
      <li>
        <b>Import animation from a file:</b>
        <br></br>
        As we covered previously, an mlg file is, itself, an animation template. To use it as so, click on this button and
          select the desired file.
      </li>
      <li>
        <b>Import animation from another format:</b>
        In case the user is working on a piece to which they want to drag an animation, use this option.
        By clicking on the button, a list of all pieces currently open will pop up. Choose the desired format and then
          click on apply.
      </li>
      <br></br>
      <p><b>Export animation to format:</b></p>
      <p>This feature helps creating several pieces at the same time.</p>
      <p>Exporting the animation to other formats is quite simple.</p>
      <p style={{marginLeft: '40px'}}>
        1. Select the main piece tab
      </p>
      <p style={{marginLeft: '40px'}}>
        2. Click on “Export animation”. A banner list will pop up.
      </p>
      <p style={{marginLeft: '40px'}}>
        3. Select the desired one and click on “apply”.
      </p>
      <br></br>
      <p>Studio compares the elements in common between the main piece and the new format. Then, it applies the same animation
        to the element, adjusting opening and reading time.</p>
      <p>If there are extra images, the softwares will apply the animation related to that element.</p>
      <p>In case it is a new text, it verifies which animation is applied to other texts and uses it.</p>
      <br></br>
      <p>Little adjustments might be required in case the elements change or are placed in different steps.However, it doesn’t
        alter any element of the project, just the animation.</p>
      <p>It is possible to separate customized templates in two types: generic and customized. In both cases, the
        functionality is the same.</p>
      <h3 id='generic-template'>Generic Template:</h3>
      <p>Just as much as native templates, generic ones apply animations to the <b>categories</b>.</p>
      <p>This kind of banner works on any creative line, with any quantity of steps.</p>
      <h3 id='customized-template'>Customized Template:</h3>
      <img src={KeyframesImg} alt='keyframes' />
      <p>It is frequently necessary to use an animation made entirely of keyframes.</p>
      <p>In such cases the names of the layers are used to apply the animation, hence the importance of organizing the psd.
        Besides, naming the PSDs in a standardized way helps when exporting the animation to a new file.</p>
      <h3 id='template-complex'>Template of complex pieces</h3>
      <p>The organization of a file depends on how the designer is going to work.</p>
      <p>It is possible to have a simple file with few layers, just as much as it is possible to have quite the opposite: a
        big file with lots of layers.</p>
      <img src={GroupsImg} alt='groups' />
      <p>In situations like this, importing the main piece may be a bit more complicated (but totally doable).</p>
      <p>First, it is necessary to structure a file that is similar to the other, with the same groups and layers. Or the user
        may copy the keyframes of the main piece and apply them to the new piece.</p>
    </div>
  )
}

export default CustomTemplate
